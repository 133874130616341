<script setup lang="ts">
import type { BaseButtonSize, BaseButtonTag, BaseButtonType } from "~/components/Base/Button.vue";
import useSSOLogin from "~/hooks/use-sso-login";

export type TAuthMode = "sign-in" | "sign-up";

interface AuthButtonProps {
  mode: TAuthMode;
  title?: string;
  disabled?: boolean;
  link?: boolean;
  fullWidth?: boolean;
  fullRounded?: boolean;
  tag?: BaseButtonTag;
  type?: BaseButtonType;
  attrType?: string;
  size?: BaseButtonSize;
  outline?: boolean;
  redirectUrl?: string;
  onSuccess?: () => void;
  onError?: () => void;
}

const props = withDefaults(defineProps<AuthButtonProps>(), {
  tag: "button",
  type: "primary",
  fullWidth: false,
  size: "medium",
});

const router = useRouter();
const appConfig = useRuntimeConfig().public;
const { executeSocialLogin, socialLoginLoading } = useSSOLogin({ onSuccess: props.onSuccess, onError: props.onError });

const loginWithProvider = computed(() => {
  if (appConfig.APP_FLAGS.hasIfaAuth && appConfig.APP_CUSTOMER.app_config.enable_skip_login_page_for_ifa_sso)
    return "ifa";
});
const loginPath = computed(() => props.mode === "sign-in" ? "/auth/login/" : "/auth/sign-up/");

const onClick = () => {
  if (loginWithProvider.value)
    return executeSocialLogin(loginWithProvider.value);

  router.push({ path: loginPath.value, query: { redirectUrl: props.redirectUrl } });
};
</script>

<template>
  <BaseButton
    :name="props.mode"
    :title="props.title"
    :disabled="props.disabled || socialLoginLoading"
    :link="props.link"
    :full-width="props.fullWidth"
    :full-rounded="props.fullRounded"
    :type="props.type"
    :attr-type="props.attrType"
    :size="props.size"
    :outline="props.outline"
    @click="onClick"
  >
    {{ props.title }}
  </BaseButton>
</template>
